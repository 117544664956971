import React, { useState } from 'react';

import Layout from "../components/layout"

export default function About({location}) {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);

  return (
  <Layout location={location}>
    <div>
      <h1>About</h1>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  </Layout>
  );
}
